<template>
  <div>
      <Navbar />
      <v-container>
          <h1>Algorithms</h1>
      </v-container>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue"
export default {
    name: "Algorithms",
    components: {
        Navbar
    }
}
</script>

<style>

</style>